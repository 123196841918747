<template>
  <div>
    <!-- Form: PropertyClassification Info Form -->
    <b-form class="mt-1">
      <b-row>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <!-- Field: name -->
          <validation-provider
            #default="validationContext"
            vid="name"
            name="name"
            rules="required"
          >
            <b-form-group
              label="إسم الصنف"
              label-for="name"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="name"
                v-model="propertyClassificationData.name"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="updatePropertyClassification"
          >
            حفظ التغييرات
          </b-button>
          <b-button
            variant="outline-secondary"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          >
            إعادة ضبط
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BFormInvalidFeedback, BFormTextarea, BFormGroup, BFormInput, BFormRadioGroup, BFormCheckboxGroup, BButton,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import { ref, onUnmounted } from '@vue/composition-api';
import vSelect from 'vue-select';
import store from '@/store';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, alphaNum, validEmail } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import { Arabic } from 'flatpickr/dist/l10n/ar.js';
import { alertMessageMixin } from '@core/mixins/ui/feedback';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import router from '@/router';
import propertyClassificationStore from '../propertyClassificationStore';

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormInvalidFeedback,
    BFormTextarea,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [alertMessageMixin],
  props: {
    propertyClassificationData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      validEmail,
      config: {
        locale: Arabic,
      },
    };
  },
  methods: {
    updatePropertyClassification() {
      this.$store
        .dispatch('app-propertyClassification/updatePropertyClassification', { propertyClassificationData: this.propertyClassificationData, id: this.propertyClassificationData.id })
        .then((response) => {
          this.$toast.success(response.data.message);
          router.push('/control-panel/property-classification').catch(() => {});
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 422) {
              this.$toast.error(err.response.data.message);
              const { message, errors } = err.response.data;
              if (errors) {
                const errorMessage = {
                  message: '',
                  type: 'danger',
                };
                Object.values(errors).forEach((error) => {
                  errorMessage.message += `${error[0]}<br>`;
                });
                this.displayMessage(errorMessage);
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  text: message,
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                },
              });
            } else {
              this.$toast.error(err.response.data.error);
            }
          }
          console.log(err);
        });
    },

  },

  setup(props) {
    const PROPERTYCLASSIFICATION_STORE_MODULE_NAME = 'propertyClassification';

    // Register propertyClassification
    if (!store.hasModule(PROPERTYCLASSIFICATION_STORE_MODULE_NAME)) store.registerModule(PROPERTYCLASSIFICATION_STORE_MODULE_NAME, propertyClassificationStore);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROPERTYCLASSIFICATION_STORE_MODULE_NAME)) store.unregisterModule(PROPERTYCLASSIFICATION_STORE_MODULE_NAME);
    });

    const oldPropertyClassificationData = JSON.parse(JSON.stringify(props.propertyClassificationData));
    const resetPropertyClassificationData = () => {
      propertyClassificationData.value = JSON.parse(JSON.stringify(oldPropertyClassificationData));
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetPropertyClassificationData);

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
