import axios from '@axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPropertyClassifications(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/property-classification', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchPropertyClassification(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/property-classification/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updatePropertyClassification(ctx, { propertyClassificationData, id }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/property-classification/${id}`, propertyClassificationData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deletePropertyClassification(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/property-classification/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addPropertyClassification(ctx, propertyClassificationData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/property-classification', propertyClassificationData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
