<template>
  <component :is="propertyClassificationData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="propertyClassificationData === undefined"
    >
      <h4 class="alert-heading">
        خطأ في جلب بيانات أصناف العقار
      </h4>
      <div class="alert-body">
        لم يتم العثور على أصناف العقار مع معرف أصناف العقار هذا.راجع
        <b-link
          class="alert-link"
          :to="{ name: 'propertyClassifications-list' }"
        >
          قائمة أصناف العقار
        </b-link>
        أصناف العقار الآخرين.
      </div>
    </b-alert>

    <b-tabs
      v-if="propertyClassificationData"
      pills
    >
      <!-- Tab: Information -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{
            $t("property_classification_edit")
          }}</span>
        </template>
        <property-classification-edit-tab-information
          :property-classification-data="propertyClassificationData"
          class="mt-2 pt-75"
        />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue';
import { ref, onUnmounted } from '@vue/composition-api';
import router from '@/router';
import store from '@/store';
import propertyClassificationStore from '../propertyClassificationStore';
import PropertyClassificationEditTabInformation from './PropertyClassificationEditTabInformation.vue';

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    PropertyClassificationEditTabInformation,
  },
  setup() {
    const propertyClassificationData = ref(null);

    const PROPERTYCLASSIFICATION_APP_STORE_MODULE_NAME = 'app-propertyClassification';

    // Register module
    if (!store.hasModule(PROPERTYCLASSIFICATION_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        PROPERTYCLASSIFICATION_APP_STORE_MODULE_NAME,
        propertyClassificationStore,
      );
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROPERTYCLASSIFICATION_APP_STORE_MODULE_NAME)) store.unregisterModule(PROPERTYCLASSIFICATION_APP_STORE_MODULE_NAME);
    });

    store
      .dispatch('app-propertyClassification/fetchPropertyClassification', {
        id: router.currentRoute.params.id,
      })
      .then((response) => {
        const { data: propertyClassification } = response.data;
        propertyClassificationData.value = propertyClassification;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          propertyClassificationData.value = undefined;
        }
      });

    return {
      propertyClassificationData,
    };
  },
};
</script>

<style>
</style>
